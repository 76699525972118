<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown && type != 'camera'"
        v-on="on"
        class="mx-2"
        elevation="0"
        style="
          min-width: 10px !important;
          height: 30px;
          width: 170px;"
      >
        <v-icon small left>$clip</v-icon>
        {{ $t("add_file") }}
      </v-btn>
      <v-btn
        v-else-if="!$vuetify.breakpoint.smAndDown && type === 'camera'"
        v-on="on"
        style="
          min-width: 10px !important;
          height: 30px;
          width: 120px;"
        class="mx-2"
        elevation="0"
        ><v-icon small left>$camera</v-icon>
        {{ $t("camera") }}
      </v-btn>
      <v-btn
        v-else-if="type != 'camera'"
        v-on="on"
        style="
          min-width: 10px !important;
          padding-right: 2px !important;
          padding-left: 14px !important;
  
        "
        x-small
        fab
        class="mx-2"
        elevation="0"
        ><v-icon small left>$clip</v-icon>
      </v-btn>
      <v-btn
        v-else
        v-on="on"
        style="
          min-width: 10px !important;
          padding-right: 2px !important;
          padding-left: 14px !important;
         
        "
        x-small
        fab
        class="mx-2"
        elevation="0"
        ><v-icon small left>$camera</v-icon>
      </v-btn>
    </template>
    <v-card id="uploadFileCard" style="overflow: hidden">
      <v-card-title>{{ $t("attach", { name: $tc("files") }) }} </v-card-title>
      <form
        action
        data-vv-scope="design-form"
        @submit.prevent="validateForm('design-form')"
      >
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pb-1">
          <v-row>
            <v-col cols="12" class="pt-4 pb-0">
              <file-upload
                style=""
                :multiple="true"
                :drop="true"
                :drop-directory="false"
                accept="image/*, .doc,.docx,.xls,.xlsx"
                v-model="files"
                ref="upload"
                class="pt-2 file-upload"
              >
                <div
                  style="
                    border: dashed var(--v-primary-base) 3px;
                    border-radius: 10px;
                    min-height: 50px;
                    text-align: center;
                    color: var(--v-primary-base);
                  "
                >
                  <div v-if="files.length" style="height: 100%">
                    <template v-for="(file, i) in files">
                      <v-chip
                        v-if="i < 2"
                        :key="file.id"
                        outlined
                        color="primary"
                        style="
                          max-width: 30%;
                          vertical-align: middle;
                          margin-top: 6px;
                        "
                        class="no-wrap mx-1"
                        >{{ file.name }}
                      </v-chip>
                      <v-chip
                        v-if="i == 2"
                        :key="file.id"
                        outlined
                        color="primary"
                        class="mx-1"
                        style="margin-top: 6px"
                      >
                        + {{ files.length - 2 }}
                        {{ $tc("file", files.length - 2) }}
                      </v-chip>
                    </template>
                  </div>
                  <!--Estilo movil-->
                  <div v-if="files.length === 0">
                    <h4 class="message pt-3" style="font-weight: normal">
                      <v-icon size="12" color="primary"
                        >${{ type === "camera" ? "camera" : "clip" }}</v-icon
                      >
                      {{ type === "camera" ? $t("photo") : $t("designs.drop") }}
                    </h4>
                  </div>

                  <div class="example-btn"></div>
                </div>
              </file-upload>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-4">
          <div class="flex-grow-1"></div>
          <v-btn
            outlined
            @click="
              dialog = false;
              files = [];
            "
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  data() {
    return { dialog: false, files: [] };
  },
  props: ["route", "type"],
  components: { FileUpload },

  methods: {
    ...mapActions("files", ["addFiles"]),
    validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        if (result & (this.files.length > 0)) {
          let formData = new FormData();

          this.files.forEach((file) => {
            formData.append("files[]", file.file);
          });
          if (this.route !== null) formData.append("route", this.route);

          this.addFiles(formData).then((data) => {
            this.dialog = false;
            this.$emit("update", data);
            this.files = [];
          });
        }
      });
    },
  },
};
</script>
<style lang="sass">


.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab)
  padding: 10px 15px !important
.file-upload
  width: 100%
  min-height: 50px

.upload
  min-height: 100px
  border-radius: 10px
  border: dashed grey 2px
  text-align: center
  .message
    padding-top: 15px
#uploadFileCard
  width: 500px
  height: 14em

@media (max-width: 400px)
  #uploadFileCard
    height: 20em
  .upload
    height: 150px
  .file-upload
    min-height: 100px
</style>
